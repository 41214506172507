<template>
  <b-row>
    <b-col cols="12" class="d-flex justify-content-end">
      <b-button variant="primary" @click="$bvModal.show('add-feedback-modal')">
        Add Feedback
      </b-button>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group
        label="Filter"
        label-cols-sm="8"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <div>
        <b-table
          striped
          hover
          responsive
          :items="opportunitiesList"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!-- <b-dropdown-item @click="viewOpportunity(data.item)"> -->
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </b-col>

    <b-modal
      id="add-feedback-modal"
      title="Add Feedback"
      @hide="resetForm"
      @ok="onSubmit"
      ok-title="Add"
    >
      <b-row>
        <b-col cols="12" class="mx-auto">
          <b-card title="Add Feedback" class="mb-3">
            <b-form @submit.prevent="onSubmit">
              <b-form-group label="Feedback Title" label-for="amount">
                <b-form-input
                  id="feedback-title"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Feedback Description"
                label-for="Description"
              >
                <b-form-textarea id="textarea-default" rows="3" required />
              </b-form-group>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BAlert,
  BCol,
  BFormGroup,
  BFormSelect,
  BContainer,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormDatepicker,
  BInputGroupAppend,
  VBModal,
  BButton,
  BSpinner,
  BModal,
  BDropdown,
  BFormTextarea,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BAvatar,
    BForm,
    BModal,
    BBadge,
    BRow,
    BContainer,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    VBModal,
    BFormInput,
    BFormDatepicker,
    BFormTextarea,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BAlert,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      // ... existing data properties ...
      selectedStatus: null, // Holds the selected status from the dropdown
      changingOpportunity: null, // Holds the opportunity being changed
      showModal: false,
      opportunitiesList: [
        {
          addedOn: "2023-12-01",
          feedbackTitle: "Feedback #1",
          desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue diam nec leo volutpat, a laoreet mauris consectetur. Etiam turpis augue, consectetur ut ipsum.  "
        },
        {
          addedOn: "2023-11-15",
          feedbackTitle: "Feedback #2",
          desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue diam nec leo volutpat, a laoreet mauris consectetur. Etiam turpis augue, consectetur ut ipsum.  "
        },
        {
          addedOn: "2023-10-30",
          feedbackTitle: "Feedback #3",
          desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue diam nec leo volutpat, a laoreet mauris consectetur. Etiam turpis augue, consectetur ut ipsum.  "
        },
      ],
      fields: [
        { key: "feedbackTitle", sortable: true, label: "Feedback Title" },
        { key: "desc", sortable: true, label: "Feedback Description" },
        { key: "addedOn", sortable: true, label: "Added On" },
        { key: "actions" },
      ],
      perPage: 5,
      totalRows: 3, // Update based on actual number of entries
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      form: {
        name: "",
        customerLead: "",
        status: null,
        salesStage: null,
        salesPerson: null,
        amount: null,
        openDate: null,
        closeDate: null,
        daysInFunnel: null,
      },
      statusOptions: ["Open", "Resolved", "Not Resolved"],
      priorityOptions: ["Low", "Medium", "High"],
      salesPersonOptions: [
        { value: "Salesperson A", text: "Salesperson A" },
        { value: "Salesperson B", text: "Salesperson B" },
        { value: "Salesperson C", text: "Salesperson C" },
        // Add more sales persons as needed
      ],
      noPermission:true
    };
  },
  computed: {},

  methods: {
    promptChangeStatus(opportunity) {
      this.changingOpportunity = opportunity;
      this.selectedStatus = opportunity.status; // Set initial dropdown value to current status
      if (
        opportunity.status === "Closed Won" ||
        opportunity.status === "Closed Lost"
      ) {
        this.$bvModal.show("status-change-not-allowed");
      } else {
        this.$bvModal.show("change-status-modal");
      }
    },
    changeStatus() {
      if (this.changingOpportunity) {
        // Check if selected status is allowed
        if (
          this.isStatusChangeAllowed(
            this.changingOpportunity.status,
            this.selectedStatus
          )
        ) {
          this.changingOpportunity.status = this.selectedStatus;
          // Add logic to persist the status change (e.g., API call)
        } else {
          this.$bvModal.show("status-change-not-allowed");
        }
      }
    },
    isStatusChangeAllowed(currentStatus, newStatus) {
      const allowedTransitions = {
        New: ["In Progress", "Closed Lost"],
        "In Progress": ["New", "Closed Lost", "Closed Won"],
        // "Closed Lost" and "Closed Won" have no allowed status changes.
      };
      return allowedTransitions[currentStatus]?.includes(newStatus) ?? false;
    },
    onSubmit() {
      // Implement submission logic here
      console.log("Form submitted:", this.form);
      // You might want to close the modal programmatically after the form is submitted
      this.$bvModal.hide("add-opportunity-modal");
    },

    resetForm() {
      // Reset the form fields to their default values
      this.form = {
        name: "",
        customerLead: "",
        status: "",
        salesStage: "",
        salesPerson: "",
        amount: "",
        openDate: "",
        closeDate: "",
        daysInFunnel: "",
      };
    },
    // correct code
    // viewOpportunity(item){
    //     return "/opportunity/view/" + item;
    // }
    // dummy code
    viewOpportunity(item) {
      return "/opportunity/view/";
    },
  },
};
</script>
