<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="!loadingSpinner">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Complaint ID" label-for="complaint-id">
              <b-form-input
                id="complaint-id"
                type="text"
                value="CP001"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Customer" label-for="cust">
              <b-form-input id="cust" type="text" value="Person 1" readonly />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="salesperson" label="Assigned to">
              <div class="form-label-group">
                <b-form-input
                  id="salesperson"
                  type="text"
                  value="Salesperson A"
                  readonly
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status" label-for="status">
              <b-form-select
                id="status-select"
                value="Open"
                :options="statusOptions"
                required
                :disabled="noPermission"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="creation-date" label="Creation Date">
              <b-form-datepicker
                id="dueDate"
                value="2023-12-01"
                class="mb-1"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Due Date" label-for="dueDate">
              <b-form-datepicker
                id="dueDate"
                
                class="mb-1"
                :disabled="noPermission"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Priority" label-for="priority-select">
              <b-form-select
                id="priority-select"
                value="Low"
                :options="priorityOptions"
                required
                :disabled="noPermission"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="justify-content-end d-flex"
            v-if="!noPermission"
          >
            <b-button variant="primary">Save</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BContainer,
  BCard,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BContainer,
    BCard,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormFile,
    BFormDatepicker,
    BCardText,
  },
  data() {
    return {
      statusOptions: ["Open", "Resolved", "Not Resolved"],
      priorityOptions: ["Low", "Medium", "High"],
      noPermission: true,
    };
  },
};
</script>
