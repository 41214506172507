<template>
  <div class="content-box">
    <div class="d-flex justify-content-center">
      <b-spinner
        variant="success"
        label="Spinning"
        v-if="showSpinner"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
      v-if="!showSpinner"
    >
      <!-- Complaint Info tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Complaint Info</span>
     
        </template>
        <ComplaintInfo/>
      </b-tab>
      <!--/ Complaint Info tab -->

      <!-- Feedback History tab -->
      <b-tab :active="isAccountSettingRoute">
        <!-- title -->
        <template #title>
          <feather-icon icon="ClockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Feedback History</span>
        </template>
        <FeedbackHistory/>
      </b-tab>
      <!--/ Feedback History tab -->
    </b-tabs>
  </div>
</template>

<script>
import api from "@/api/api-v1-config";
import { BTabs, BTab, BSpinner } from "bootstrap-vue";
import ComplaintInfo from "./ComplaintInfo.vue";
import FeedbackHistory from "./FeedbackHistory"
export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    ComplaintInfo,FeedbackHistory
  },
  data() {
    return {
      token: localStorage.getItem("accessToken"),
      role: JSON.parse(localStorage.getItem("userData")).role,

      showSpinner: false,
      options: [],
      logs: [],
      permissions: [],
      isAccountSettingRoute: false,
    };
  },
};
</script>
